.configuration-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.export {
    font-size: 13px;
    color: #98678f;
    cursor: pointer;
}
.export svg {
    font-size: 20px;
    float: left;
    margin-right: 5px;
}
.integration-icon {
    height: 30px;
}