.boarding-container {
    position: relative;
    overflow: hidden;
    background: linear-gradient(to right, transparent 50%, #f5f5f5 50%), linear-gradient(to right, #afafaf, #afafaf);
    background-size: 16px 1px;
    background-repeat: repeat-x;
    background-position: 0 226px;
    background-color: #fff;
} 

.boarding-relative {
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    height: 500px;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.boarding-relative::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}
.boarding-endbar {
    position: absolute;
    width: 40px;
    height: 60px;
    color: #afafaf;
    margin-left: 105px;
    top: 20px;
    zoom: 0.6;
    font-size: 38px;
}
.boarding-beginbar {
    position: absolute;
    width: 40px;
    height: 60px;
    color: #afafaf;
    margin-left: 105px;
    top: 20px;
    zoom: 0.6;
    font-size: 38px;
    left: -2px;
}
.boarding-midbar {
    position: absolute;
    width: 40px;
    height: 60px;
    color: #afafaf;
    margin-left: 105px;
    top: 20px;
    zoom: 0.6;
    font-size: 38px;
    left: calc(50% - 120px);
}


.scroll-indicator.left {
    border-left: 5px solid #98678f;
    border-top: 5px solid #98678f;
    width: 10px;
    height: 10px;
    transform: rotate(-45deg);
    margin-top: 40px;
    position: absolute;
    top: 60px;
    left: 10px;
}
.scroll-indicator.right {
    border-right: 5px solid #98678f;
    border-bottom: 5px solid #98678f;
    width: 10px;
    height: 10px;
    transform: rotate(-45deg);
    margin-top: 40px;
    right: 10px;
    position: absolute;
    top: 60px;
}

.add-item-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

    .add-item-button:hover {
        background-color: #0056b3;
    }



    .boarding-bar {
        background-color: #afafaf;
        height: 1px;
        top: 226px;
        position: absolute;
    }

    .boarding-bar::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: repeating-linear-gradient( to right, transparent, transparent 20px, #ffffff 20px, #ffffff 26px );
    }

    .boarding-bar::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 10px;
        width: 2px;
        height: 40px;
        background-color: #afafaf;
        transform: translateY(-50%);
    }


.boarding-anchor-top
.boarding-anchor-checked {
    font-size: 44px;
    position: absolute;
    left: -70px;
    top: 45px;
}
.boarding-anchor-bottom
.boarding-anchor-checked {
    font-size: 44px;
    position: absolute;
    left: -70px;
    top: 15px;
}
.boarding-anchor-top {
    position: absolute;
    top: 330px;
    margin-left: 40px;
    zoom: 0.6;
}

    .boarding-anchor-top::before {
        content: attr(title);
        font-size: 20px;
        font-weight: 600;
        position: absolute;
        margin-top: -40px;
        width: 200px;
        text-align: center;
        margin-left: -35px;
        color: inherit;
    }

.boarding-anchor-bottom {
    position: absolute;
    top: 188px;
    margin-left: 40px;
    zoom: 0.6;
}

    .boarding-anchor-bottom::before {
        content: attr(title);
        font-size: 20px;
        font-weight: 600;
        position: absolute;
        margin-top: 250px;
        width: 200px;
        text-align: center;
        margin-left: -35px;
        color: inherit;
    }

.boarding-anchor-top div.content {
    width: 200px;
    position: absolute;
    margin-left: 85px;
    border-bottom: 3px solid #666;
    padding-bottom: 10px;
    color: #666;
    height: 100px;
    display: flex;
    align-items: end;
    justify-content: left;
    margin-top: -110px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.boarding-anchor-bottom div.content {
    width: 200px;
    position: absolute;
    margin-left: 85px;
    border-top: 3px solid #666;
    padding-top: 10px;
    color: #666;
    margin-top: -230px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

 
.icon-space svg {
    margin: 10px 0px 20px -12px;
    font-size: 22px;
    background-color: #f5f5f5;
}


.type-title {
    color: #98678f;
    font-size: 22px;
    text-transform: uppercase;
}
.type-title::before { 
    content: '';
    display: inline-block;
    width: 40px;
    height: 10px;
    margin-right: 10px;
    border-top: 1px solid #98678f;
}
.type-title::after { 
    content: '';
    display: inline-block;
    width: 40px;
    height: 10px;
    margin-left: 10px;
    border-top: 1px solid #98678f;
}

.task-item {
    display: flex;
    gap: 10px;
    border-left: 2px solid #666;
}

.task-content {
    display: flex;
    flex-direction: column;
}

.task-header {
    display: flex;
    gap: 10px;
}
.task-space-task {
    font-size: 16px;
    font-weight: 500;
}
.task-space-date {
    font-size: 12px;
    margin-top: 2px;
    margin-left: 30px;
    display: block;
}
.task-space-name {
    color: #98678f;
    font-size: 12px;
}


.profile {
    border-right: 1px solid #E3E3E2;
}

.profile-avantar {
    max-width: 120px;
    margin: 0 auto;
}
.avatar-upload-label {
    width: 35px;
    height: 35px;
    padding: 5px;
    text-align: center;
    cursor: pointer;
}
.img-thumbnail {
    width: 120px;
    height: 120px;
}
.taskdetails { 
    padding-left: 48px;
}
.taskdetails .task-details {
    border-left: 2px solid #666; 
}
.taskdetails .col-3 {
    zoom: 0.75;
}
.taskdetails .col-9 {
    zoom: 0.75;
}
.text-primary {
    color: #98678f !important;
}