.reset-password-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
}

.reset-password-container {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}
#password, #confirmPassword {
    height: 40px;
} 
.reset-password-container h1 {
    text-align: center;
    margin-bottom: 1rem;
}
.reset-password-header {
    text-align: center;
    margin-bottom: 30px;
}

.reset-password-header h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

.input-group {
    margin-bottom: 1rem;
}

    .input-group label {
        display: block;
        margin-bottom: 0.5rem;
    }

    .input-group input {
        width: 100%;
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 4px;
    }

.set-button {
    background-color: #98678f;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

    .set-button:hover {
        background-color: #ac2499;
    }

    .set-button:disabled {
        background-color: #cccccc;
        cursor: not-allowed;
    }

.message {
    text-align: center;
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-radius: 4px;
}

.message.error {
    background-color: #f8d7da;
    color: #721c24;
}

.message.success {
    background-color: #d4edda;
    color: #155724;
}
