 
button {
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
.search-container {
    float:right;
    min-width: 400px;
}
.search-input {
    height: 34px;
    width: 200px !important;
    border-radius: 3px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;
    float: right;
    margin-top: 0px;
}
.flow-description {
    font-size: 90%;
    color: #b7b5b5;
}
.new-button {
    float: left;
    width: 180px;
    margin-bottom: 40px;
    border-radius: 4px;
    background-color: #bd9db8;
    white-space: nowrap;
    word-wrap: normal;
    color: #fff7f7;
    padding: 10px 20px 10px 20px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.add-button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #bd9db8;
    font-size: 30px;
    text-align: center;
    color: #fff7f7;
    padding: 0px 0px 4px 0px;
    line-height: 0px;
    position: absolute;
    z-index: 10;
    bottom: 10px;
    right: 10px;
}
.pill {
    background-color: #e0e0e0;
    border-radius: 4px;
    padding: 6px 16px;
    margin-right: 40px;
}
.search-button {
    background-color: #98678f;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 34px;
    width: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
}
.rdt_Table{
    padding: 0px;
}
.tab-content header {
    padding: 0px;
}
.rdt_TableHeader {
    padding-right: 0px;
}
.floating-action-button {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    background-color: #bd9db8;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;
    border: none;
    cursor: pointer;
}

    .floating-action-button:hover {
        background-color: #98678f /*#135d6a*/;
    }

.floating-action-button svg {
    width: 1.5rem;
    height: 1.5rem;
    color: white;
}

.form-check-input:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none !important;
}


input[type=text], input[type=email], input[type=password], input[type=pasword], input[type=date], textarea {
    width: 100%;
    padding: 6px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px !important;
}

input[type=text]:read-only {
    color: #b7b5b5;
    cursor: default;
}

    input[type=text]:focus, input[type=email]:focus, input[type=password]:focus, input[type=pasword]:focus, textarea:focus, input[type=date]:focus, select:focus {
        border: 1px solid #98678f;
        outline: none;
        box-shadow: none !important;
    }
.passwordvisibility {
    position: absolute;
    right: 9px;
    top: 23px;
    font-size: 23px;
    cursor: pointer;
}
.form-control:focus {
    border: 1px solid #98678f !important;
    outline: none !important;
    box-shadow: none !important;
}

.form-check-input:checked {
    background-color: #98678f !important;
    border-color: #98678f !important;
}
select.form-control {
    height: 38px;
    padding-top: 5px;
    padding-left: 8px;
    appearance: auto;
}
select.form-control:hover, select.form-control:active, select.form-control:focus {
    height: 38px;
    padding-top: 5px;
    padding-left: 8px;
    appearance: auto;
} 
.list-edit {
    color: #666;
    cursor: pointer;
    font-size: 21px;
}
.list-delete {
    color: #666;
    cursor: pointer;
    font-size: 21px;
    margin-left: 10px;
}
.list-edit:hover, .list-delete:hover {
    color: #000;
}
input.form-control {
    font-size: 12px;
    padding: 8px 10px;
}
a {
    color: #98678f !important;
    text-decoration: none !important;
}
a:hover {
    text-decoration: underline !important;
}
.ql-editor {
    background-color: #fff;
    min-height: 300px;
    max-height: calc(100vh - 300px);
}
.ql-toolbar.ql-snow {
    background-color: #d0d0d0;
}
.save-button {
    background-color: #bd9db8;
    width: 180px;
    margin-left: 10px;
    margin-top: 10px;
    float: right;
    border-radius: 4px;
}
.save-button:hover {
    background-color: #98678f;
}
.remove-button, .previous-button {
    background-color: #808080;
    width: 180px;
    margin-right: 10px;
    margin-top: 10px;
    float: left;
    border-radius: 4px;
}
.cancel-button {
    background-color: #808080;
    width: 180px;
    margin-left: 10px;
    margin-top: 10px;
    float: right;
    border-radius: 4px;
}
.action-button {
    background-color: transparent;
    width: auto;
    padding: 0px;
    color: #98678f;
}
.action-button:hover {
    text-decoration: underline;
}
.message-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.message-content {
    padding: 20px;
}

.success-alert {
    background-color: #94628c;
    color: white;
    padding: 20px 10px;
    text-align: left;
    width: 100%;
    z-index: 1000;
    margin: 0 0 0-20px;
    position: fixed;
    opacity: 0.9;
    top: 0px;
} 
.success-alert svg {
    font-size: 28px;
}
.error-alert {
    background-color: #575757;
    color: white;
    padding: 20px 10px;
    text-align: left;
    width: 100%;
    z-index: 1000;
    margin: 0 0 0-20px;
    position: fixed;
    opacity: 0.9;
    top: 0px;
}
.error-alert svg {
    font-size: 28px;
}
.right-sidebar {
    background-color: white;
    margin: 0px;
    padding: 25px;
    bottom: 0px;
    border-left: 1px solid #e3e3e3;
    position: absolute;
    right: 0px;
    top: 53px;
    font-size: 85%
}
    .right-sidebar hr {
        margin-top: 10px;
        margin-bottom: 5px;
        border-color: #999;
    }

.right-sidebar span.title {
    font-weight: 500;
    line-height: 22px;
}