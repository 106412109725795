/* LoginPage.css */
.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
    background-image: url(../images/hero-2.jpg);
}

.login-container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 40px;
    width: 500px;
}

.login-header {
    text-align: center;
    margin-bottom: 30px;
}
 

.login-header h1 {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

.login-form {
    display: flex;
    flex-direction: column;
}

.input-group {
    margin-bottom: 20px;
}


.next-button,
.login-button {
    background-color: #98678f;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

    .next-button:hover,
    .login-button:hover {
        background-color: #ac2499/*#216b79*/;
    }

.back-link {
    display: block;
    text-align: center;
    margin-top: 10px;
    color: #98678f;
    text-decoration: none;
}
.login-logo {
    font-size: 34px;
    text-transform: uppercase;
    font-weight: 100;
}

.login-footer {
    text-align: center;
    margin-top: 20px;
}

    .login-footer a {
        color: #98678f;
        text-decoration: none;
    }

.message {
    text-align: center;
    margin-top: 20px;
    color: #4caf50;
}
