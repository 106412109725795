/* Perform.css */
.perform-page {
    display: flex;
    justify-content: flex-start; /* Default for mobile */
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    background-image: url(../images/hero-2.jpg);
    padding: 0; /* Remove any padding */
}

/* Restore center alignment for desktop */
@media screen and (min-width: 768px) {
    .perform-page {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: center; 
        padding-top: 40px;
        background-color: red;
    }
}

.perform-container {
    background-color: white;
    padding: 20px; /* Reduced padding for mobile */
    width: 100%; /* Full width */
    min-height: 100vh; /* Full height */
    box-shadow: none; /* Remove shadow on mobile */
    border-radius: 0; /* Remove border radius for full-bleed effect */
}

/* Responsive adjustments */
@media screen and (min-width: 768px) {
    /* Tablet and desktop styles */
    .perform-page {
        padding: 20px;
    }

    .perform-container {
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        width: 80%;
        min-height: auto;
        padding: 40px;
    }
}

.perform-header {
    text-align: center;
    margin-bottom: 30px;
    padding-top: 20px; /* Add some top padding */
}

    .perform-header h1 {
        font-size: 20px; /* Slightly smaller for mobile */
        color: #333;
        margin-bottom: 20px;
    }

@media screen and (min-width: 768px) {
    .perform-header h1 {
        font-size: 24px;
    }
}

.perform-logo {
    font-size: 28px; /* Slightly smaller for mobile */
    text-transform: uppercase;
    font-weight: 100;
}

@media screen and (min-width: 768px) {
    .perform-logo {
        font-size: 34px;
    }
}

/* Adjust verification code inputs for mobile */
.code-inputs {
    display: flex;
    gap: 4px; /* Smaller gap for mobile */
    justify-content: center;
    margin: 1rem 0;
}

.code-input {
    width: 35px !important; /* Slightly smaller for mobile */
    height: 35px;
}

@media screen and (min-width: 768px) {
    .code-inputs {
        gap: 8px;
    }

    .code-input {
        width: 40px !important;
        height: 40px;
    }
}

/* Make buttons more mobile-friendly */
.send-code-button, .verify-button {
    background-color: #98678f;
    width: 100%; /* Full width on mobile */
    max-width: 200px;
    margin: 10px 0;
}

/* Rest of your existing styles remain unchanged */
.perform-footer {
    text-align: center;
    margin-top: 20px;
}

    .perform-footer a {
        color: #98678f;
        text-decoration: none;
    }

.perform-details {
    background-color: #f4f4ff;
    padding: 15px;
    color: rgb(33, 37, 41);
    font-size: 13px;
    border-radius: 8px;
    margin-bottom: 25px;
    padding: 10px 20px 1px 20px !important;
    position: relative;
}

.perform-info {
    position: absolute;
    color: #fff;
    right: -20px;
    font-size: 74px;
    top: -20px;
}

.send-code-button:hover,
.verify-button:hover {
    background-color: #ac2499;
}

.send-code-button:disabled,
.verify-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.verification-container {
    margin-top: 2rem;
}
.save-button.full-width {
    width: 100% !important;
}
.error-message {
    color: #98678f;
    border: 1px solid #98678f; 
    margin: 1rem 0;
    padding: 30px 8px;
    background-color: #f4f4ff; 
    text-align: center; 
}

.success-alert2 {
    background-color: #94628c;
    color: white;
    padding: 20px 10px;
    text-align: left;
    width: 100%;
    margin: 0px;
    opacity: 0.6;
}

.success-alert2-info {
    margin-bottom: 40px;
    float: left;
    margin-right: 10px;
    margin-top: 5px;
}
