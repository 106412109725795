.popup-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.popup-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 500px;
    margin-left: 180px;
    max-width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.popup-modal-title {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 1.2em;
}

.popup-modal-message {
    margin-bottom: 60px;
}

.popup-modal-actions {
    display: flex;
    justify-content: flex-end;
}

.popup-modal-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-left: 10px;
}

    .popup-modal-button.modal-button-primary, .popup-modal-button.modal-button-success {
        background-color: #98678f !important;
        color: white;
    }
.popup-modal-button.modal-button-secondary {
    background-color: #f0f0f0 !important;
    color: #333;
} 

.popup-modal-button:hover {
    opacity: 0.8;
}

.popup-modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
}
.footer-buttons {
    position: absolute;
    bottom: 0px;
    right: 0px;
    left: 0px;
}
.footer-buttons button {
    width: 140px;
}

.footer-buttons button.btn-outline-secondary {
    margin-right: 10px;
}


    .footer-buttons button.btn-primary {
        background-color: #98678f;
        border-color: #98678f;
    }
    .footer-buttons button.btn-success {
        background-color: #98678f;
        border-color: #98678f;
    }