
.tabs-container {
    margin-top: 20px;
}

.tabs-header {
    position: relative;
    border-bottom: 1px solid #ccc;
}

.tabs-list {
    display: flex;
    justify-content: space-around;
}

.tab {
    padding: 10px 20px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #98678f;
    transition: color 0.3s;
}

.tab.active {
    font-weight: 600;
    color: #98678f;
}

.tab-indicator {
    position: absolute;
    bottom: 0;
    height: 2px;
    background-color: #98678f;
    transition: left 0.3s ease-in-out;
}

.tab-content {
    padding: 20px;
    background-color: #fff;
}
.tab.disabled { 
    cursor: default;
}
