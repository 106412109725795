/* ForgotPasswordPage.css */
.forgot-password-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
    background-image: url(../images/hero-2.jpg);
}

.forgot-password-container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 40px;
    width: 500px;
}

.forgot-password-header {
    text-align: center;
    margin-bottom: 30px;
}

    .forgot-password-header h1 {
        font-size: 24px;
        color: #333;
        margin-bottom: 20px;
    }

.forgot-password-logo {
    font-size: 34px;
    text-transform: uppercase;
    font-weight: 100;
}

.forgot-password-form {
    display: flex;
    flex-direction: column;
}

.input-group {
    margin-bottom: 20px;
}

    .input-group input {
        width: 100%;
        padding: 12px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 16px;
    }

.reset-button,
.back-to-login-button {
    background-color: #98678f;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

    .reset-button:hover,
    .back-to-login-button:hover {
        background-color: #ac2499;
    }

.forgot-password-footer {
    text-align: center;
    margin-top: 20px;
}

    .forgot-password-footer a {
        color: #98678f;
        text-decoration: none;
    }

.message {
    text-align: center;
    margin-top: 20px;
    color: #4caf50;
}

.success-message {
    text-align: center;
    margin-top: 20px;
}

    .success-message p {
        color: #4caf50;
        margin-bottom: 20px;
    }
