/* Dashboard.css */

.dashboard {
    display: flex;
    height: 100%;
    min-height: 100vh;
}

/* Sidebar Styles */
.sidebar {
    width: 250px;
    background-color: #98678f/*d13ebd*/;
    color: #fff;
    padding: 20px;
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    transition: width 0.3s ease;
}
.flag { 
    margin-right: 3px;
    cursor: pointer;
    float: right;
}
.sub-menu {
    padding-left: 30px;
}

.sub-item {
    font-size: 0.9em;
    padding: 5px 0;
}

.sidebar.collapsed {
    width: 60px;
    padding: 20px 10px;
}

.sidebar.collapsed .sub-menu {
    padding-left: 0px;
}
    .sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
}

.logo {
    font-size: 26px;
    line-height: 42px;
    text-transform: uppercase;
    font-weight: 100;
}
    .logo svg {
        float: left;
    }
    .logo div {
        float: right;
    }

.toggle-sidebar {
    position: absolute;
    right: -5px;
    top: 20px;
    transform: translateY(-50%);
    background-color: #be8eb6 /*#d13ebd*/;
    border: none;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
}
.toggle-sidebar:hover {
    background-color: #98678f /*#d13ebd*/;
}
 
.sidebar-item {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 10px;
    margin-bottom: 5px;
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
}

    button.sidebar-item:hover, .sidebar-item.active {
        background-color: #98678f /*#216b79*/;
        border-radius: 3px;
    }

.sidebar-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    margin-right: 10px;
} 

.sidebar-text {
    flex-grow: 1;
}

.sidebar.collapsed .logo {
    display: none;
}

.sidebar.collapsed button.toggle-sidebar {
    right: 0px;
    top: 8px;
}
.sidebar.collapsed nav {
    margin-top: 20px;
}

.sidebar.collapsed .sidebar-text {
    display: none;
}

/* Main Content Styles */
.main-content {
    flex-grow: 1;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
}

.main-header {
    background-color: #fff;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e3e3e3;
    color: #817d7d;
}

    .main-header h1 {
        font-size: 18px;
        margin: 0;
        font-weight: normal;
    }

.header-actions {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* User Menu Styles */
.user-menu-container {
    position: relative;
}

.user-icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #98678f /*#216b79*/;
    color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    font-size: 13px;
    cursor: pointer;
}

.user-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    padding: 10px;
    z-index: 1000;
    min-width: 200px;
}

.user-info {
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 10px;
}

.user-name {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.user-email {
    display: block;
    font-size: 0.8em;
    color: #666;
}
.task-listicon {
    font-size: 4em;
}

.menu-item {
    display: flex;
    align-items: center;
    padding: 8px 10px;
    width: 100%;
    border: none;
    background: none;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.2s;
    color: #413f3f;
}

    .menu-item:hover {
        background-color: #f5f5f5;
    }

    .menu-item svg {
        margin-right: 10px;
    }

/* Content Area Styles */
.content-area { 
    padding: 20px;
}

.empty-state {
    text-align: center;
    margin-top: 23%;
}

.empty-icon {
    width: 100px;
    margin-bottom: 20px;
}

.subtext {
    color: #777;
    font-size: 0.9em;
    text-decoration: none;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .sidebar {
        width: 60px;
        padding: 20px 10px;
    }

        .sidebar .logo, .sidebar .sidebar-text, button.toggle-sidebar {
            display: none;
        }

    .sub-menu {
        padding-left: 0px;
    }

    .main-header {
        padding: 10px;
    }

        .main-header h1 {
            font-size: 16px;
        }
}
